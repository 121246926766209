import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import FAQ from "../components/faq"
import PropTypes from "prop-types"

import { Helmet } from "react-helmet"

const propTypes = {
  data: PropTypes.string.isRequired,
}

export default function Faqs(props) {
  return (
    <Layout>
      <Helmet>
        <script type="application/ld+json">
          {`
        {
          "@context": "https://schema.org",
          "@type": "FAQPage",
          "mainEntity": [{
            "@type": "Question",
            "name": "How does STUDi improve my child’s understanding?",
            "acceptedAnswer": {
              "@type": "Answer",
              "text": "Studi is designed to encourage your child to learn systematically, using scientifically proven practices. It comes with an Adaptive Studi Plan that helps them set a learning goal against a specific date – say, a term exam start date. It enables them to organize their studies efficiently and ensures that they dedicate the right amount of time to each subject."
            }
          },{
            "@type": "Question",
            "name": "What are the benefits of the Big Idea feature?",
            "acceptedAnswer": {
              "@type": "Answer",
              "text": "Through this feature, Studi offers your child an overview of a chapter before delving into the details. It addresses two important questions: “What is it about?” and “How does it matter?”?The Big Idea links new ideas or concepts to their previous knowledge and sets?the context for further explanation?of the concept."
            }
          },{
            "@type": "Question",
            "name": "Does STUDi offer practice tests or revisions?",
            "acceptedAnswer": {
              "@type": "Answer",
              "text": "Practice indeed makes perfect. But there is an efficient way to practice – it's called spaced practice. So, Studi provides practice sessions soon after a concept is taught. It asks them to practice the same concept sometime later as well. This is because a single practice, even if done well, is not enough for long-term retention. Studi’s practice?sessions?are?spaced and repeated, enabling learners to retain the concepts?thoroughly.?Self-tests?are?provided?at?periodic intervals?to help?students gauge?their confidence level?at every step."
            }
          },{
            "@type": "Question",
            "name": "What happens if my child tends to favour one subject over the other?",
            "acceptedAnswer": {
              "@type": "Answer",
              "text": "Studi uses a method called ‘interleaving’ to ensure that adequate attention is paid to all subjects and not just a select few. After they complete a logical chunk of learning in one subject, they will be recommended to try out a lesson from another subject. This also works wonders in cementing better retention of the concepts they learn."
            }
          },{
            "@type": "Question",
            "name": "What is the textbook mapping feature? How is it helpful?",
            "acceptedAnswer": {
              "@type": "Answer",
              "text": "Our?learning content is mapped with your child’s school syllabus?so that there is a?deeper connection?between?what is taught at?school?and what?they?learn at?home.??This helps your child to revise the subject matter at home in perfect alignment with the way the lessons?progress in school."
            }
          },{
            "@type": "Question",
            "name": "PRODUCT | What platforms is the product available on?",
            "acceptedAnswer": {
              "@type": "Answer",
              "text": "Our product is available on Google Play, App Store and as a web browser product."
            }
          },{
            "@type": "Question",
            "name": "Is internet required to use the product?",
            "acceptedAnswer": {
              "@type": "Answer",
              "text": "Yes, an active internet connection is required to use the product."
            }
          },{
            "@type": "Question",
            "name": "How can I track my child's progress?",
            "acceptedAnswer": {
              "@type": "Answer",
              "text": "Studi?allows you to observe how your child’s learning progresses. This lets you provide the support and encouragement your child needs in their learning journey.?It?also enables you to?stay abreast of and involve yourself in?their?studies?by assigning tests, worksheets, quizzes and more."
            }
          },{
            "@type": "Question",
            "name": "What is the Adaptive Studi Plan?",
            "acceptedAnswer": {
              "@type": "Answer",
              "text": "The Adaptive Studi Plan encourages students to define their learning goals and become independent learners. Through this Adaptive Plan, Studi ensure that learners spend adequate time on every subject in the curriculum. If there is an upcoming class test, the Adaptive Plan helps students prepare for it quite efficiently, letting them know how much to study each day to meet the targets."
            }
          },{
            "@type": "Question",
            "name": "Which subjects are provided with STUDi?",
            "acceptedAnswer": {
              "@type": "Answer",
              "text": "Following subjects are provided grade-wise: 
        Grades 1-5: Maths, EVS 
        Grades 6-8: Maths, Science, History, Geography, Civics"
            }
          },{
            "@type": "Question",
            "name": "Can I get a free trial of the product?",
            "acceptedAnswer": {
              "@type": "Answer",
              "text": "Yes, you can avail a free trial for two weeks."
            }
          },{
            "@type": "Question",
            "name": "Can I cancel my plan?",
            "acceptedAnswer": {
              "@type": "Answer",
              "text": "Yes, you can. In case of an auto-renewal cycle, you can place a request to discontinue your plan at any time. However, the cancellation will take effect only at the end of the billing cycle, and the content will be available for that billing cycle."
            }
          },{
            "@type": "Question",
            "name": "What happens if I buy an annual subscription midterm? Will I be allowed to change the grade for the next academic year during the subscription?",
            "acceptedAnswer": {
              "@type": "Answer",
              "text": "With an annual plan, you can change your child’s class once during the 1-year billing cycle."
            }
          },{
            "@type": "Question",
            "name": "How is the product priced?",
            "acceptedAnswer": {
              "@type": "Answer",
              "text": "Here’s how the pricing works: 
        Monthly plan: Rs 899 per month (You pay Rs. 10,788 per year.) 
        Half-yearly plan: Rs 5100 for six months (You pay Rs. 10,200 per year.)"
            }
          },{
            "@type": "Question",
            "name": "How can I pay?",
            "acceptedAnswer": {
              "@type": "Answer",
              "text": "You can pay using a debit card or a credit card."
            }
          },{
            "@type": "Question",
            "name": "Can I pay the subscription amount as EMI?",
            "acceptedAnswer": {
              "@type": "Answer",
              "text": "No, currently EMI schemes are not available."
            }
          },{
            "@type": "Question",
            "name": "Whom do I contact if I have questions or am facing any issues?",
            "acceptedAnswer": {
              "@type": "Answer",
              "text": "In case you are facing any issues, please use any one of the following 3 ways to reach us: 
        1. Fill out the form with details on the Contact Us page in the Support Section of our website.
        2. You can directly call us on 7506617777
        3. You can write to us at support@tatastudi.com"
            }
          }]
        }`}
        </script>
      </Helmet>
      <SEO
        title="Frequently Asked Questions | Tata Studi"
        lang="en"
        description="Get answers to all your questions about Tata Studi learning app in FAQ page. Tata Studi website helps you understand elearning in K-12, and science of learning."
      />

      <div>
        <div className="pageBanner supportPage bannerText">
          <h1 className="displayNone">FAQs</h1>
          <h2>Support - FAQs</h2>
        </div>
      </div>
      <FAQ data={props.data.faqData} />
    </Layout>
  )
}

export const query = graphql`
  query {
    faqData: allNodeFaqs {
      nodes {
        field_faqs_categories
        field_faqs_question
        field_faqs_answer {
          processed
        }
      }
    }
  }
`
